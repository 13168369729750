import React, { useEffect, useMemo, useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TitleGroup from "../components/common/title-group";
import { formatGQLQuery } from "../utils";
import queryMenu from "../graphql/menu";
import {
  popularArticles as popularArticlesQuery,
  articlesByCategoryWithPagination,
} from "../graphql/article";
import Item from "../components/common/item";
import Tag from "../components/common/tag";
import contactAndAddress from "../graphql/footer";
import useFetch from "../hooks/useFetch";
import Button from "../components/common/button";
import { FaArrowDown } from "react-icons/fa";

export default function Detail({ serverData, ...props }) {
  const {
    menu,
    popularArticles = [],
    articles = [],
    contact = {},
    address = {},
    slug = ''
  } = serverData;

  const [results, setResults] = useState(articles);
  const [page, setPage] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const bodyRequest = useMemo(() => {
      return JSON.stringify({
        query: formatGQLQuery(
          [
            articlesByCategoryWithPagination,
          ],
          [
            { key: "$articlesByCategoryWithPaginationCategory", type: "String!" },
            { key: "$articlesByCategoryWithPaginationPage", type: "Int!" },
            { key: "$articlesByCategoryWithPaginationPageSize", type: "Int!" },
          ]
        ),
        variables: {
          articlesByCategoryWithPaginationCategory: slug,
          articlesByCategoryWithPaginationPageSize: 10,
          articlesByCategoryWithPaginationPage: page,
        },
      })
  }, [slug, page])

  const {
    loading,
    data: response,
    error,
  } = useFetch(page === 1 ? null : process.env.GRAPHQL_URL, bodyRequest, {
    dependencies: [page],
  });

  const onLoadMore = () => {
    setPage(page + 1);
  };

  const isShowMore = useMemo(() => {
    if (!error && !loading && showLoadMore && results.length >= 10) {
      return true;
    } else {
      return false;
    }
  }, [loading, error, showLoadMore, results]);

  useEffect(() => {
    const data = response?.articlesByCategoryWithPagination?.data;

    if (data && Array.isArray(data)) {
      if (data.length === 0) {
        setShowLoadMore(false);
      } else {
        setResults((prev) => {
          return [...prev, ...data];
        });
      }
    }
  }, [response]);

  return (
    <Layout menu={menu} contact={contact} address={address} {...props}>
      <Seo />
      <main className="flex-1">
        <div className="container grid grid-cols-1 gap-0 overflow-hidden py-4 md:grid-cols-6 md:gap-10">
          <div className="col-span-4">
            {Array.isArray(results) &&
              results.map(({ attributes: item }, index) => {
                const url = item.url || `/detail/${item?.slug}`;
                const imageAlt =
                  item.cover?.data?.attributes?.alternativeText ||
                  item.cover?.data?.attributes?.caption ||
                  item.title;
                let subtitleContent = item.description;
                if (Array.isArray(item?.blocks)) {
                  const richText = Array(...item?.blocks).find(
                    (block) =>
                      block?.["__typename"] === "ComponentSharedRichText"
                  );
                  subtitleContent = richText?.body;
                }
                const subtitle = React.createElement("div", {
                  children: subtitleContent,
                  className: "rich-text-content line-clamp-3 text-gray-500",
                });
                const imageUrl = item.cover?.data?.attributes?.url;
                return (
                  <Item
                    key={`article-item-${index}`}
                    containerClassName={`${!!imageUrl ? "" : "justify-end"} my-6 pb-6 border-b border-gray-300 flex-row-reverse justify-end`}
                    image={imageUrl}
                    imageAlt={imageAlt}
                    label={new Date(item.date).toLocaleDateString("id-ID", {
                      dateStyle: "medium",
                    })}
                    title={item.title}
                    subtitle={subtitle}
                    url={url}
                    textContainerClassName="mr-3"
                    thumbnailClassName="min-w-[64px] min-h-[96px] md:min-w-[144px] md:min-h-[112px]"
                    imageHeight={112}
                    imageWidth={144}
                  />
                );
              })}

            <>
              {isShowMore && (
                <Button
                  onClick={onLoadMore}
                  className={`mx-auto mt-5 flex flex-row items-center`}
                >
                  Muat lebih banyak <FaArrowDown className="ml-2" />
                </Button>
              )}
            </>
          </div>
          <div className="col-span-2">
            <TitleGroup title="Populer" containerClass="mb-3 mt-4" />
            {popularArticles.map(({ attributes: item }, index) => {
              const url = item.url || `/detail/${item?.slug}`;
              const imageAlt =
                item.cover?.data?.attributes?.alternativeText ||
                item.cover?.data?.attributes?.caption ||
                item.title;
              const subtitleText = `${item?.category?.data?.attributes?.name}`;
              const subtitle = (
                <div className=" w-fit">
                  <Tag
                    text={subtitleText}
                    color="onSecondary"
                    className="text-fontPrimary"
                  />
                </div>
              );
              return (
                <Item
                  key={`popular-article-item-${index}`}
                  containerClassName="my-3"
                  image={item.cover?.data?.attributes?.url}
                  imageAlt={imageAlt}
                  label={new Date(item.date).toLocaleDateString("id-ID", {
                    dateStyle: "medium",
                  })}
                  title={item.title}
                  url={url}
                  subtitle={subtitle}
                />
              );
            })}
          </div>
        </div>
      </main>
    </Layout>
  );
}

export async function getServerData({ params }) {
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  const isoThirtyDaysAgo = thirtyDaysAgo.toISOString();

  const options = {
    cache: "no-cache",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await fetch(process.env.GRAPHQL_URL_SSR, {
      ...options,
      body: JSON.stringify({
        query: formatGQLQuery(
          [
            queryMenu,
            articlesByCategoryWithPagination,
            popularArticlesQuery,
            contactAndAddress,
          ],
          [
            { key: "$articlesByCategoryWithPaginationCategory", type: "String!" },
            { key: "$articlesByCategoryWithPaginationPage", type: "Int!" },
            { key: "$articlesByCategoryWithPaginationPageSize", type: "Int!" },
            { key: "$popularArticlesLimit", type: "Int!" },
            { key: "$popularArticlesMaxDate", type: "DateTime!" },
          ]
        ),
        variables: {
          articlesByCategoryWithPaginationCategory: params?.slug,
          articlesByCategoryWithPaginationPageSize: 10,
          articlesByCategoryWithPaginationPage: 1,
          popularArticlesLimit: 7,
          popularArticlesMaxDate: isoThirtyDaysAgo,
        },
      }),
    });

    const { data } = await response.json();
    const articles = data?.articlesByCategoryWithPagination?.data;


    return {
      props: {
        menu: data?.menu?.data,
        popularArticles: data?.popularArticles?.data,
        articles,
        contact: data?.contact?.data,
        address: data?.address?.data,
        slug: params?.slug
      },
      status: 200,
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
    };
  }
}
